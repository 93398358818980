<template>
  <div class="lodylearn-captcha">
    <div class="captcha-loader" v-if="!image || !uuid">
      <loader></loader>
    </div>
    <div class="captcha-container border border-gray-300 rounded-xl px-4 py-3 w-full" v-else>

      <div class="flex flex-nowrap">
        <img :src="image" alt="Captcha" class="my-auto ml-auto mr-1"/>
        <button @click.prevent="reset" class="bg-gray-500 cursor-pointer rounded-xl px-2 py-2 font-white my-auto mr-auto ml-1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="18" width="18">
            <path fill="white" d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
        </button>
      </div>
      <div class="font-light text-sm mt-3">Veuillez saisir le texte présent sur l'image ci-dessus</div>
        <input type="text" placeholder="Captcha"
               @input="handleVerify"
               v-model="result" class="text-primary-900 text-sm font-light mt-3 border border-gray-300 rounded-xl px-4 py-3 w-full text-center"/>
    </div>
    <span v-if="error" class="text-sm text-red-800">{{ error }}</span>
    <span v-if="getError" class="text-sm text-red-800">{{ getError }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Loader from '../loader.vue';
import captchaService from '../../services/captcha.service';

@Component({
  name: 'recaptcha',
  props: {
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    Loader,
  },
  mounted() {
    this.reset();
  },
  data() {
    return {
      uuid: null,
      image: null,
      result: null,
    };
  },
  computed: {},
  methods: {
    async reset() {
      this.image = null;
      this.uuid = null;
      const response = await captchaService.fetchImage();
      this.uuid = response.data.uuid;
      this.image = response.data.image;
      this.result = null;
      this.handleExpired();
    },
    handleVerify() {
      this.$emit('input', `${this.uuid}:${this.result}`);
    },
    handleError() {
      this.$emit('input', null);
    },
    handleExpired() {
      this.$emit('input', null);
    },
    handleRender() {
      this.$emit('input', null);
    },
  },
})
export default class Recaptcha extends Vue {
}
</script>

<style scoped>

</style>
