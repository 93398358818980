<template>
  <div class="modal-emoji">
    <global-modal-element :open="open" :animated="animated" :allow-overflow="true">
      <div class="emoji-container"></div>
      <div class="content mt-6">
        <h3 class="modal-title text-center font-title text-primary-900 text-3xl">
          <slot name="title"></slot>
        </h3>
        <div class="modal-text text-center italic text-primary-900 mt-2 mb-4">
          <slot></slot>
          <div class="footer-item mt-5 flex flex-col" v-if="footer">
            <span class="text-sm">Retrouvez toutes nos actualités sur les réseaux sociaux</span>
            <div class="socials mt-2 mx-auto flex">
              <a v-for="rs in socials"
                 class="mr-4"
                 :href="rs.url" target="_blank" :key="rs.url">
                <img :src="rs.image" :alt="rs.url" width="34" height="34"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </global-modal-element>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import GlobalModalElement from './global-modal-element.vue';
import { resolve } from '../../_helpers/image.helper';

const facebook = resolve('rs/Icone-RS_FB_fond.svg');
const instagram = resolve('rs/Icone-RS_Insta_fond.svg');
const linkedin = resolve('rs/Icone-RS_Linkedin_fond.svg');
const youtube = resolve('rs/Icone-RS_Youtube_fond.svg');

@Component({
  name: 'emoji-modal',
  components: { GlobalModalElement },
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
    open: {
      required: true,
      type: Boolean,
    },
    animated: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      socials: [
        {
          image: facebook,
          url: 'https://facebook.com/lodylearn',
        },
        {
          image: instagram,
          url: 'https://www.instagram.com/lodylearn_/?hl=fr',
        },
        {
          image: linkedin,
          url: 'https://fr.linkedin.com/company/tipengo',
        },
        {
          image: youtube,
          url: 'https://www.youtube.com/channel/UCaaK7a1BbKT40eGoCyzTGnQ/featured',
        },
      ],
    };
  },
  computed: {},
  methods: {},
})
export default class EmojiModal extends Vue {
}
</script>

<style scoped>
  .emoji-container {
    position: relative;
    width: 128px;
    margin: 0 auto;
  }
  .emoji-container::before {
    content: '';
    position: absolute;
    width: 128px;
    height: 128px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    top: -104px;
  }
</style>
