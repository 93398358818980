<template>
  <div class="relative">
    <div class="btn-group">
      <label v-if="useLabel" :for="label" class="uppercase text-xl leading-6 block mb-2">{{ label }}</label>
      <div v-for="(option, index) in options" :key="option.value" @click.prevent="select(option)"
              class="font-light text-sm border border-gray-300 px-4 py-3 cursor-pointer inline-block"
              :class="{
        'rounded-l-xl': index === 0,
        'rounded-r-xl': index === options.length - 1,
        'border-red-800': error !== null,
        'bg-primary-500 text-white border-primary-500': value === option.value,
      }">
        <span>{{ option.label }}</span>
      </div>
    </div>
    <span v-if="error" class="text-sm text-red-800">{{ error }}</span>
    <span v-if="getError" class="text-sm text-red-800">{{ getError }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'flat-basic-select',
  props: {
    useLabel: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      required: false,
      default: '',
      type: String,
    },
    validation: {
      type: String,
      default: 'none',
      required: false,
    },
  },
  watch: {
    value(v) {
      this.setValue(v);
    },
  },
  data() {
    return {
      internalValue: '',
      currentHideStatus: false,
    };
  },
  mounted() {
    if (this.hideBehaviour) {
      this.currentHideStatus = true;
    }
  },
  computed: {
    currentType() {
      if (this.currentHideStatus === true) return 'password';
      return this.type;
    },
    getError() {
      return null;
    },
  },
  methods: {
    select(option) {
      this.setValue(option.value);
    },
    setValue(value) {
      this.internalValue = value;
      this.$emit('input', value);
    },
  },
})
export default class FlatBasicInput extends Vue {
}
</script>

<style scoped>

</style>
