<template>
  <div>
    <app-form>
      <div class="error-container mb-8" v-if="globalErrors.length > 0">
        <div class="message mb-2 rounded bg-red-600 text-white border border-red-800 rounded px-3 py-2"
             :key="index"
             v-for="(error, index) in globalErrors">
          <div class="text-sm">{{ error.message }}</div>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 items-center gap-4 mb-4">
        <flat-basic-select label="Genre"
                           :options="[{value: 'M', label: 'Monsieur'}, {value: 'F', label: 'Madame'}]"
                           v-model="gender"
                           :error="errorGender ? 'Champ manquant': null"></flat-basic-select>
      </div>
      <div class="grid sm:grid-cols-2 items-center gap-4 mb-4">
        <flat-basic-select label="Genre"
                           :options="[{value: 'PART', label: 'Particulier'}, {value: 'PRO', label: 'Pro'}]"
                           v-model="type"
                           :error="errorType ? 'Champ manquant': null"></flat-basic-select>
      </div>
      <div class="grid sm:grid-cols-2 items-center gap-4 mb-4">
        <flat-basic-input label="Nom" v-model="lastname"
                          :error="errorLastname ? 'Champ manquant': null"></flat-basic-input>
        <flat-basic-input label="Prénom" v-model="firstname"
                          :error="errorFirstname ? 'Champ manquant': null"></flat-basic-input>
      </div>
      <div class="mb-4">
        <flat-basic-input label="Email" type="email" v-model="email" validation="email"
                          :error="errorEmail ? 'Email invalide ou déjà utilisée': null"></flat-basic-input>
      </div>
      <div class="mb-4">
        <flat-basic-input label="Mot de passe" v-model="password" :hide-behaviour="true" validation="password"
                          :error="errorPassword ? 'Le mot de passe ne respecte pas les critères de sécurité': null"></flat-basic-input>
      </div>
      <div class="mb-2">
        <flat-checkbox-input v-model="handicap" label="Je suis porteur de handicap"></flat-checkbox-input>
      </div>
      <div class="mb-2">
        <flat-checkbox-input v-model="specificNeed" label="J'ai un besoin spécifique de formation"></flat-checkbox-input>
      </div>
      <div class="mb-3">
        <flat-checkbox-input v-model="prerequisites" label="*Je m'engage à respecter les prérequis des formations suivies"></flat-checkbox-input>
      </div>
      <div >
      <flat-checkbox-input v-model="optIn" label="">*En cliquant sur « S'inscrire », je certifie avoir 16 ans ou plus et accepte les
        <span @click="openCgu" class="cursor-pointer text-primary-900 font-normal">Conditions générales d'utilisation</span>,
        <router-link to="/politique-de-confidentialite" class="cursor-pointer text-primary-900 font-normal">la Politique de confidentialité</router-link>,
        <a href="javascript:openAxeptioCookies()" class="cursor-pointer text-primary-900 font-normal">la Politique relative aux cookies</a>

        et je consens à recevoir les actualités et promotions.
      </flat-checkbox-input>
      </div>
      <div class="grid sm:grid-cols-2 items-center gap-4 mb-4 mt-4">
        <div>
          <recaptcha ref="captcha" v-model="captcha" :error="errorCaptcha ? 'Captcha incorrect': null"></recaptcha>
        </div>
        <div class="flex w-full">
          <button
              @click.prevent="handleSend"
              :disabled="buttonDisabled"
              :class="{
              'bg-primary-500 text-white hover:bg-primary-900': !buttonDisabled,
              'bg-gray-600 text-white': buttonDisabled,
            }"
              class="my-auto mx-auto sm:mr-0 rounded-full px-6 py-2 transition">
            <span class="w-7px h-7px bg-white animate-ping rounded-full ml-3" v-if="loading"></span>
            Je m'inscris
          </button>
        </div>
      </div>
    </app-form>
    <div class="mb-4 text-gray-300 text-center font-light text-sm">
      <p class="mb-3">Vous avez déjà un compte ?
        <router-link :to="{name: $routeNames.LogIn, params: {redirect: $route.params.redirect}}"
                     class="cursor-pointer text-primary-900 font-normal">Connectez-vous
        </router-link>
      </p>
      <p class="mb-1">Si vous rencontrez un problème
        <router-link :to="{name: $routeNames.Contact}" class="underline cursor-pointer hover:text-primary-900">
          contactez-nous
        </router-link>
      </p>
      <p>
        <span class="underline cursor-pointer hover:text-primary-900" @click="openCgu">
          Conditions générales d'utilisations
        </span>
      </p>
    </div>
    <!-- sign in modal -->
    <emoji-modal v-if="signInModal" :open="signInModal" :animated="true" :footer="true">
      <template v-slot:title>Merci pour votre inscription</template>
      <p>Pour valider définitivement votre inscription, merci de suivre le lien envoyé par email</p>
      <router-link :to="redirectUrl" class="inline-block mt-4 bg-primary-500 not-italic text-white border-2 border-primary-500 py-2 px-6 rounded-full transition hover:bg-white hover:text-primary-900">
        Continuer
      </router-link>
    </emoji-modal>
    <!-- end of sign in modal -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import FlatBasicInput from '@/components/form-input/flat-basic-input.vue';
import FlatCheckboxInput from '@/components/form-input/flat-checkbox-input.vue';
import Recaptcha from '@/components/form-input/recaptcha.vue';
import AppForm from '@/components/form-input/app-form.vue';
import { isValidEmail, isValidPassword } from '@/_helpers/validation.helper';
import FlatBasicSelect from '@/components/form-input/flat-basic-select.vue';
import EmojiModal from '@/components/global-modals/emoji-modal.vue';
import {
  alertDanger, alertSuccess, alertWarning, alertInfo,
} from '../../_helpers/alert.helper';
import MetaHelper from '../../_helpers/meta.helper';

@Component({
  name: 'SignIn',
  metaInfo() {
    return MetaHelper.register();
  },
  components: {
    FlatBasicSelect,
    Recaptcha,
    FlatBasicInput,
    FlatCheckboxInput,
    AppForm,
    EmojiModal,
  },
  data() {
    return {
      lastname: '',
      firstname: '',
      email: '',
      optIn: false,
      handicap: false,
      specificNeed: false,
      prerequisites: false,
      password: '',
      gender: '',
      type: 'PART',
      captcha: null,
      errorEmail: false,
      errorPassword: false,
      errorFirstname: false,
      errorLastname: false,
      errorGender: false,
      errorType: false,
      errorCaptcha: false,
      signInModal: false,
      redirectUrl: {
        name: 'LogIn',
      },
    };
  },
  computed: {
    isValidFirstname() {
      return this.firstname.length > 0 && this.firstname.length <= 35;
    },
    isValidLastName() {
      return this.lastname.length > 0 && this.lastname.length <= 35;
    },
    isValidEmail() {
      return isValidEmail(this.email);
    },
    isValidPassword() {
      return isValidPassword(this.password);
    },
    isValidCaptcha() {
      return this.captcha !== null;
    },
    isValidGender() {
      return this.gender === 'M' || this.gender === 'F';
    },
    isValidType() {
      return this.type === 'PRO' || this.type === 'PART';
    },

    buttonDisabled() {
      return this.loading || ![
        this.isValidLastName,
        this.isValidEmail,
        this.isValidFirstname,
        this.isValidCaptcha,
        this.isValidType,
        this.isValidPassword,
      ].reduce((p, v) => (p && v), true);
    },
    ...mapState({
      loading: (s) => s.auth.registration.loading,
      globalErrors: (s) => s.auth.registration.fieldErrors.filter((f) => f.field === 'all'),
      hasError: (s) => s.auth.registration.fieldErrors.length > 0,
    }),
  },
  methods: {
    openCgu() {
      this.$store.commit('globalModal/cgu/SET_OPEN', true);
    },
    isValidPrerequisites() {
      return this.prerequisites;
    },
    isValidOptIn() {
      return this.optIn;
    },
    resetErrors() {
      this.errorEmail = false;
      this.errorPassword = false;
      this.errorFirstname = false;
      this.errorLastname = false;
      this.errorGender = false;
      this.errorType = false;
      this.errorCaptcha = false;
    },
    async logIn() {
      try {
        await this.$store.dispatch('auth/connect', {
          email: this.email,
          password: this.password,
          stayConnected: true,
        });
        if (this.$store.getters['auth/isAuth']) {
          alertSuccess('Vous êtes maintenant connecté(e)', 2000);
          await this.$router.push(this.$route.params.redirect || { name: 'Search' });
          return true;
        }
      } catch (e) {
        //
      }
      return false;
    },
    async handleSend() {
      if (!this.isValidOptIn() || !this.isValidPrerequisites()) {
        alertInfo('Veuillez cochez les cases requises', 5000);
        return;
      }
      try {
        await this.$store.dispatch('auth/registration/send', {
          firstname: this.firstname,
          lastname: this.lastname,
          password: this.password,
          email: this.email,
          captcha: this.captcha,
          gender: this.gender,
          type: this.type,
          handicap: this.handicap,
          specificNeed: this.specificNeed,
        });
      } catch (e) {
        if (e.response && e.response.data) {
          this.errorFirstname = e.response.data.message.indexOf('firstname') !== -1;
          this.errorLastname = e.response.data.message.indexOf('lastname') !== -1;
          this.errorEmail = e.response.data.message.indexOf('email') !== -1;
          this.errorPassword = e.response.data.message.indexOf('password') !== -1;
          this.errorGender = e.response.data.message.indexOf('gender') !== -1;
          this.errorType = e.response.data.message.indexOf('type') !== -1;
          this.errorCaptcha = e.response.data.message.indexOf('captcha') !== -1;
          console.log(this.errorCaptcha);
        }
      }

      if (!this.hasError) {
        const loginSuccess = await this.logIn();
        if (loginSuccess) return;
        if (this.$route.params.redirect) {
          this.redirectUrl = {
            name: 'LogIn',
            params: { redirect: this.$route.params.redirect },
          };
          this.signInModal = true;
          return;
        }
        this.redirectUrl = {
          name: 'LogIn',
        };
        this.signInModal = true;
      }
    },
  },
})
export default class SignIn extends Vue {
}
</script>

<style scoped>

</style>
