import AppService from '@/services/app.service';

class CaptchaService extends AppService {
  async fetchImage() {
    return this.exec<{ error?: boolean, uuid: string, image: string}>({
      url: '/v1/captcha',
    });
  }
}

const captchaService = new CaptchaService();
export default captchaService;
